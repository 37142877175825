<template>
  <section>
    <div class="row" style="margin-top: 30px;">
      <div class="col-md-12">
        <div class="row">
          <box  color="2c82e0" name="Escolas" link="educacao/escolas" ></box>
          <box  color="ffd43a" name="Turmas" link="educacao/turmas" ></box>
          <box  color="18ad46" name="Disciplinas" link="educacao/disciplina" ></box>
          <box  color="154ec1" name="Alunos" link="educacao/alunos" ></box>
          <box  color="2c82e0" name="Servidores" link="educacao/servidores" ></box>
        </div>
      </div>
    </div>

    <br>
    <section class="col-md-12 pm-card mensagem-caixa">
      <h2 class="titulo-caixa">Caixa de Mensagens</h2>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-8 lg:col-2">
          <pm-Button disabled @click="enviarMensagemModal" label="Enviar Mensagem" class="p-button-danger" />
          <br><br>
          <pm-Menu :model="items" style="width:100%!important;" />

        </div>
        <div class="field col-10 md:col-10 lg:col-10">
          <pm-TabView v-if="recebidas == 1">
            <pm-TabPanel header="Não Lidas">
              <div>
                <listaDeMensagem :env="0" :msgAll="msgAll" :links="links" @abrirMensagem="abrirMensagem" @paginas="paginas" />
              </div>
            </pm-TabPanel>
            <pm-TabPanel header="Lidas">
              <div>
                <listaDeMensagem :env="0" :msgAll="msgAll2" :links="links2" @abrirMensagem="abrirMensagem"  @paginas="paginasLidas" />
              </div>
            </pm-TabPanel>
          </pm-TabView>

          <div v-else-if="recebidas==0">
            <listaDeMensagem :env="1" :msgAll="msgAll" :links="links" @abrirMensagem="abrirMensagem"  @paginas="paginas" />
          </div>
        </div>
      </div>
    </section>

    <pm-Dialog header="Envio de Mensagem" v-model:visible="popmsg2" :style="{width: '50vw'}" :maximizable="true" :modal="true">
      <div class="p-fluid formgrid grid" >
        <pm-TabView class="field col-12">
          <pm-TabPanel header="Mensagem Individual">

            <div class="field col-12 md:col-12 lg:col-12" >
              <label style="font-weight: 400 !important;margin-top: 15px;">  </label>
              <span class="p-input-icon-left ">
                <i class="pi pi-search" />
                <pm-AutoComplete v-model="pesquisaservidor" :suggestions="filterservidor" @complete="pesquisarServidor" field="nome_completo"
                  placeholder="Pesquisar Nome do Servidor"
                />
              </span>
            </div>

            <div class="field col-12 md:col-12 lg:col-12">
              <label>Assunto</label>
              <pm-InputText  v-model="mensagem.titulo" />
            </div>
            <div class="field col-12 md:col-12">
              <pm-Textarea v-model="mensagem.msg" rows="5" cols="30" />
            </div>
            <div class="field col-2">
              <pm-Button  label="Enviar" icon="pi pi-check" @click="enviarMensagem(1,'n')" autofocus />
            </div>
          </pm-TabPanel>

          <pm-TabPanel header="Mensagem ao Setor" >
            <div class="field col-12 md:col-5 lg:col-5" >
              <label>Setor</label>
              <select class="p-inputtext p-component" v-model="mensagem.setor"
                style="appearance: revert !important;width: 100% !important;" >
                <option  value="3" >Secretária</option>
                <option  value="7" >Coordenacao pedagogica</option>
                <option  value="5" >Professor</option>
                <option  value="2" >Supervisão administrativa</option>
              </select>
            </div>

            <div class="field col-12 md:col-12 lg:col-12">
              <label>Assunto</label>
              <pm-InputText  v-model="mensagem.titulo" />
            </div>
            <div class="field col-12 md:col-12">
              <pm-Textarea v-model="mensagem.msg" rows="5" cols="30" />
            </div>
            <div class="field col-2">
              <pm-Button  label="Enviar" icon="pi pi-check" @click="enviarMensagem(2,'n')" autofocus />
            </div>
          </pm-TabPanel>
        </pm-TabView>
      </div>
    </pm-Dialog>


    <pm-Dialog  v-model:visible="popmsg" :style="{width: '50vw'}" :maximizable="true" :modal="true">
      <template #header>
        <h3>Menssagens</h3>
      </template>

      <div v-if="conteudo_mensagem.servidor_id == id_servidor_logado" class="container darker">
        <h5>Título: {{conteudo_mensagem.titulo}}</h5>
        <span class="badge badge-prym" style="float:right" v-if="conteudo_mensagem.servidor_nome_enviou != '' ">{{conteudo_mensagem.servidor_nome_enviou}}</span>
        <p><strong>Mensagem:</strong> {{conteudo_mensagem.msg}}</p>
        <span class="time-right">{{conteudo_mensagem.hora}}</span>
      </div>

      <div v-else class="container">
        <h5>Título: {{conteudo_mensagem.titulo}}</h5>
        <span class="badge badge-prym" v-if="conteudo_mensagem.servidor_nome_enviou != '' ">{{conteudo_mensagem.servidor_nome_enviou}}</span>
        <p><strong>Mensagem:</strong> {{conteudo_mensagem.msg}}</p>
        <span class="time-left">{{conteudo_mensagem.hora}}</span>
      </div>

      <div  v-for="item in conteudo_mensagem.respostas_da_msg" :key="item.id">
        <div v-if="id_servidor_logado == item.servidor_id" class="container darker">
          <h5>Título: {{item.titulo}}</h5>
          <span class="badge badge-prym right"   style="float:right" v-if="item.servidor_nome != '' ">{{item.servidor_nome}}</span>

          <p><strong>Mensagem:</strong> {{item.msg}}</p>
          <span class="time-right">{{item.hora}}</span>
        </div>

        <div v-else class="container">
          <h5>Título: {{item.titulo}}</h5>
          <span class="badge badge-prym right"  v-if="item.servidor_nome != '' ">{{item.servidor_nome}}</span>
          <p><strong>Mensagem:</strong> {{item.msg}}</p>
          <span class="time-left">{{item.hora}}</span>
        </div>
      </div>

      <div class="p-fluid formgrid grid" v-if="responder_msg" >
        <div class="field col-12 md:col-12 lg:col-12" >
          <hr>
          <h3>
            Responder Mensagem
          </h3>
        </div>

        <div class="field col-12 md:col-12 lg:col-12">
          <label>Assunto</label>
          <pm-InputText  v-model="mensagem.titulo" />
        </div>
        <div class="field col-12 md:col-12">
          <pm-Textarea v-model="mensagem.msg" rows="5" cols="30" />
        </div>
        <div class="field col-2">
          <pm-Button  label="Enviar" icon="pi pi-check" @click="enviarMensagem(1,'resp')" autofocus />
        </div>
      </div>
      <template #footer>
        <pm-Button v-if="this.env == 0 && responder_msg == 0"  label="Responder Mensagem ?" icon="pi pi-check" @click="responderMensagem" autofocus />
      </template>
    </pm-Dialog>

    <!-- <pm-Dialog v-model:visible="modalVideo" :maximizable="true" :modal="true">
      <p style="font-weight: 700; font-size: 20px; text-align: center">A partir do dia 13/11/2023 iremos implementar a autenticação de 2 fatores(2FA) no login como demonstrado abaixo:</p>
      <iframe :width="screeSize" :height="720" src="https://www.youtube.com/embed/ZrU2F1gFIh4" title="Passo a passo login com 2fa" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </pm-Dialog> -->
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import { LoginInfo } from "@/class/login";
import { Escola } from "@/class/escolas";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { Servidores } from "@/class/servidores.js";
import { Comunicacao } from "@/class/comunicacao";
import { Calendario } from "@/class/calendario";
import listaDeMensagem from "@/components/painel/listar_mensagens.vue";
import axios from 'axios'

import box from "@/components/painel/box.vue";

import VaChart from '@/components/va-charts/VaChart.vue'
import Pagination from 'v-pagination-3';

//import { getDonutChartData } from '@/data/charts/DonutChartData'

import { useGlobalConfig } from 'vuestic-ui'

export default defineComponent({
  components: { VaChart, Pagination,listaDeMensagem,box },
  data () {
    return {
      donutChartData: null,
      donutChartData2: null,
      redeChartData: null,
      rede2ChartData: null,
      modalVideo: false,
      id_servidor_logado:null,
      situacaoAno:1,
      escolaALL: [],
      escola:0,
      ano:0,
      rede:[],
      rede2:[],
      dados3:[],
      dados2:{
        evadidos: 0,
        falecidos: 0,
        transferidos: 0,
        turmas_evadidos:[]
      },
      dados:{
        escola: {
            nome: "",
            turmas_qdt: 0,
            alunos_qdt: 0,
            eja_qdt: 0,
            ern_qdt: 0,
            fundamental_1_qdt: 0,
            fundamental_2_qdt: 0,
            media_total_eja: 0,
            media_total_ern: 0,
            media_total_fundamental1: 0,
            media_total_fundamental2: 0,
            medias:{

            },
        },
        segmento:{
           eja:{lenght:0},
           ern:{lenght:0},
           fundamental_1:{lenght:0},
           fundamental_2:{lenght:0},
        }
      },
      fund1:false,
      page: 1,
      perPage: 10,

      page_n: 1,
      perPage_n: 10,

      links:[],
      links2:[],

      env:null,
      mensagem:[],
      msgAll:[],
      msgAll2:[],
      msgRecebidas:[],
      popmsg:false,
      popmsg2:false,
      conteudo_mensagem:[],
      filterservidor:[],
      recebidas:1,

      escolha:1,
      responder_msg:0,
      resposta_servidor_id:0,

      pesquisaservidor:null,

      currentPage: 1,
      items:[
        {
          label: 'Mensagens',
          items: [{
            label: 'Recebidas',
              icon: 'pi pi-refresh',
              command: () => {
                this.mensagemRecebidas(1);
              }
            },
            {
              label: 'Enviadas',
              icon: 'pi pi-times',
              command: () => {
                this.mensagemEnviadas(1);
              }
            }
          ]
        },
      ],
      escolaALL:[],
      relatorioALL:[],
      segmentoALL: [],
      serieALL:[],
      anoAll:[],
      simples:{
        ano:2023,
        escola_id:0,
        segmento_id: 0,
      },
      segmentos:[],
      serie:{},
      qdt_seg:0,
      todos_segmentos: 1,
      colunas:{
        numero: true
      },
      imprimir:0,
      visivel:false,
      screeSize:1280,
    }
  },
  async beforeMount() {
    if (this.$router.options.history.state.back === '/auth/login') {
      if (screen.width < 1280) {
        this.screeSize = screen.width;
      }
      this.modalVideo = true
    }
    this.anosCalendario();
    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
    this.ano = parseInt(sessionStorage.getItem("anoSelecionado"));
    this.listarTudo();
    //await this.buscarDadosRede();
    //await this.buscarDadosRedeEventos();
    //await this.buscarDadosEscola();
    //await this.buscarDadosEscolaAlunosEventos();

    //await this.buscarDadosEscolaNotas();

    await this.mensagemRecebidas(this.currentPage);
    await this.mensagemRecebidasMensagensAbertas(this.currentPage);

    const token = sessionStorage.getItem("token");
    const whoiam = await LoginInfo.WhoIam(token);
    this.id_servidor_logado = whoiam.data.usuario.id;

  },
  computed: {

    displayedRecords() {
      const startIndex = this.perPage * (this.page - 1);
      const endIndex = startIndex + this.perPage;
      if(endIndex != 'undefined'){
        return this.dados2.turmas_evadidos.slice(startIndex, endIndex);
      }
    },



    theme() {
      return useGlobalConfig().getGlobalConfig().colors
    },


  },

  methods:{
    async anosCalendario () {
      try {
        const dt = await Calendario.ano();
        this.anoALL = dt.data;
        this.anoALL.sort((a, b) => (a.ano > b.ano) ? 1 : ((b.ano > a.ano) ? -1 : 0));
      } catch (e) {

      }
    },

    async trocarStatus(d){
      const data = await Comunicacao.trocaStatusMensagem(d.id);

      this.mensagemRecebidasMensagensAbertas(1);
      this.mensagemRecebidas(1);
    },

    async buscarEscolaALL() {
      const data = await Escola.obtemTodos();
      this.escolaALL = data.data;
    },

    async buscarSegmentoALL() {
      this.segmentoALL = [];
      this.serie = [];
      this.qdt_seg =0;

      const data = await SegmentoEscolar.obtemTodos();
      //this.segmentoALL = data.data;
      /*for (const it of data.data) {
        this.segmentos.id.push(it.id);
      }*/
      //this.qdt_seg = this.segmentos.id.length;

      for (const it of data.data) {
        const dt = await SegmentoEscolar.listarSegmentosPorSerie(it.id);
        const se = [];
        for (const ite of dt.data) {
          const n = {
            nome : ite.nome,
            id: ite.id,
            segmento_id: it.id
          };
          se.push(n);
        }
        /*let nv = {
          segmento: it,
          series: se
        }*/
        it.series = se;
        this.segmentoALL.push(it)

        /*for (const i of dt.data) {
          this.serie[i.id] = true
        }*/

        console.log(this.segmentoALL)
      }
    },

    async buscarRelatorioALL() {
      this.imprimir = 0;
      this.relatorioALL = [];

      const data = await axios.post('/relatorio',{
        ano: this.simples.ano,
        escola_id: this.simples.escola_id,
        segmentos: this.segmentos,
        series: this.serie,
      });

      const arr = [];
      for (const it of data.data) {
        const alunos_arr=[];
        alunos_arr.push(it.alunos);

        for (const key in alunos_arr) {
          arr.push(alunos_arr[key]);
        }
      }

      for (let index = 0; index < arr.length; index++) {
        for (let j = 0; j < arr[index].length; j++) {
          this.relatorioALL.push(arr[index][j]);
        }
      }

      const mywindow = window.open('', 'PRINT', 'height=auto,width=800');
      mywindow.document.write(`<html><head> <title> Relatorio ${this.relatorioALL[0]['ano']} </title>`);
        mywindow.document.write(`
        <style>
          body {
              margin: 0;
              color: #212529;
              text-align: center;
              background-color: #fff;
          }
          table {
              border-collapse: collapse;
          }
          table tr td, table tr th{
            font-size: 12px !important;
          }
          thead {
            display: table-header-group;
            vertical-align: middle;
            border-color: inherit;
          }
          tr {
            display: table-row;
            vertical-align: inherit;
            border-color: inherit;
          }
          .table-bordered {
              border: 1px solid #dee2e6;
          }
          .table {
              width: 100%;
              margin-bottom: 1rem;
              color: #212529;
          }
          .table-bordered thead td, .table-bordered thead th {
              border-bottom-width: 2px;
          }
          .table thead th {
            vertical-align: bottom;
            border-bottom: 2px solid #dee2e6;
          }
          .table-bordered td, .table-bordered th {
              border: 1px solid #dee2e6;
          }
          .table td, .table th {
            padding: 0.75rem;
          }
        </style>
        `);
      mywindow.document.write('</head><body >');
      mywindow.document.write('<table class="table table-bordered">');

      mywindow.document.write(`
        <thead>
          <tr>
            <th> N° </th>
            <th> TURMA / TURNO </th>
            <th> ALUNOS POR TURMA </th>
            <th> ALUNO(A) </th>
            <th> MÃE E PAI </th>
            <th> NASCIMENTO </th>
            <th> NATURALIDADE </th>
            <th> SEXO </th>
            <th> CPF </th>
            <th> PNE </th>
          </tr>
        </thead>`
      );
      for (let i= 0; i < this.relatorioALL.length; i++) {
        mywindow.document.write(`
          <tbody>
            <tr>
              <td>${i+1}</td>
              <td style="text-align:center;">${ this.relatorioALL[i]['turma_nome']  +'<br><br>'+ this.relatorioALL[i]['turno'] }</td>
              <td style="text-align:center;">${ this.relatorioALL[i]['quantidade_alunos'] }</td>
              <td>${ this.relatorioALL[i]['aluno']['nome']  +' '+  this.relatorioALL[i]['aluno']['sobrenome'] }</td>
              <td>${ this.relatorioALL[i]['aluno']['nome_mae']  +'<br><br>'+  this.relatorioALL[i]['aluno']['nome_pai'] }</td>
              <td style="text-align:center;">${ this.relatorioALL[i]['aluno']['nascimento_formatado'] }</td>
              `);

              if(this.relatorioALL[i]['aluno']['naturalidade']){
                 mywindow.document.write(`<td style="text-align:center;">${ this.relatorioALL[i]['aluno']['naturalidade'] }</td>`);
              }else{
                mywindow.document.write(`
                  <td></td>`);
              }

              mywindow.document.write(`<td style="text-align:center;">${ this.relatorioALL[i]['aluno']['sexo'] }</td>`);

              if(this.relatorioALL[i]['aluno']['cpf']){
                mywindow.document.write(`
                <td style="text-align:center;">${ this.relatorioALL[i]['aluno']['cpf'] }</td>`);
              }else{
                mywindow.document.write(`
                <td></td>`);
              }

              if(this.relatorioALL[i]['aluno']['nee']){
                mywindow.document.write(`
                  <td style="text-align:center;">Sim</td>`);
              }else{
                mywindow.document.write(`
                  <td style="text-align:center;">Não</td>`);
              }

              mywindow.document.write(`
            </tr>
          </tbody>`
        );

      }

      mywindow.document.write('</table>');
      //mywindow.document.write(document.getElementById('tabela_id').innerHTML);
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus();

      mywindow.print();
      mywindow.close();

      //this.visivel = true;
      //this.imprimir = 1;
    },

    async paginas(n){
      if(n.url != null){
        if(!n.active){
          const data = await axios.get(n.url);
          this.msgRecebidas = [];
          this.msgAll = [];

          this.msgRecebidas = data.data.data;
          this.links = data.data.links;
          this.msgAll = this.msgRecebidas;
        }
      }
    },

    async paginasLidas(n){
      if(n.url != null){
        if(!n.active){
          const data = await axios.get(n.url);
          this.msgRecebidas = [];
          this.msgAll2 = [];

          this.msgRecebidas = data.data.data;
          this.links2 = data.data.links;
          this.msgAll2 = this.msgRecebidas;
        }
      }
    },

    async mensagemRecebidas(n){
      this.recebidas = 1;
      this.msgAll = [];
      const data = await Comunicacao.obtemTodosMsgRecebidosNaoLidas(n);
      this.msgRecebidas = data.data.data;
      this.links = data.data.links;
      this.msgAll = this.msgRecebidas;
    },

    async mensagemRecebidasMensagensAbertas(n){
      this.recebidas = 1;
      this.msgAll2 = [];
      const data = await Comunicacao.obtemTodosMsgRecebidosAbertas(n);
      this.msgRecebidas = data.data.data;
      this.links2 = data.data.links;
      this.msgAll2 = this.msgRecebidas;
    },

    async mensagemEnviadas(){
      this.recebidas = 0;
      this.msgAll = [];
      const data = await Comunicacao.obtemTodosMsgEnviadas(this.currentPage);
      this.msgRecebidas = data.data.data;
      this.links = data.data.links;
      this.msgAll = this.msgRecebidas;
    },

    abrirMensagem(data,env){
      if(env == 0){
        this.trocarStatus(data);
      }
      this.env = env;
      this.popmsg = true;
      this.conteudo_mensagem = data;
      this.resposta_servidor_id = data.servidor_id;

    },

    responderMensagem(){
      this.responder_msg = 1;
    },

    enviarMensagemModal(){
      this.popmsg2 = true;
    },

    async enviarMensagem(tipo, resposta){
      try {
        const token = sessionStorage.getItem("token");
        const whoiam = await LoginInfo.WhoIam(token);
        const login_id = whoiam.data.usuario.id;

        let id_serv_resp = 0;

        if(this.mensagem.titulo == null){
          alert("O titulo está Vazio!");
          return false;
        }

        if(this.mensagem.msg == null){
          alert("O conteudo da mensagem está Vazio!");
          return false;
        }


        if(tipo == 1){

          if(resposta == "resp"){
            // envio ao servidor resposta de uma mensagem aberta
            const nv = {
              titulo: this.mensagem.titulo,
              msg: this.mensagem.msg,
              setor_de_recebimento: null,
              servidor_id: login_id,
              servidor_id_resposta: this.resposta_servidor_id,
              resposta_msg_id: this.conteudo_mensagem.id,
            };

            const data = await Comunicacao.enviarMensagem(nv,2);
          }else{
            // envio ao servidor não resposta de mensagem
              if(this.pesquisaservidor != null){
                id_serv_resp = this.pesquisaservidor.id;
              }

              if(id_serv_resp == 0){
                alert("Selecione o Servidor onde será enviado a mensagem!");
                return false;
              }

              const nv = {
                titulo: this.mensagem.titulo,
                msg: this.mensagem.msg,
                setor_de_recebimento: null,
                servidor_id: login_id,
                servidor_id_resposta: id_serv_resp,
              };

              const data = await Comunicacao.enviarMensagem(nv,1);
          }

        }else if(tipo == 2){

          if(this.mensagem.setor == null){
            alert("Selecione o Setor onde será enviado a mensagem!");
            return false;
          }

          const nv = {
            titulo: this.mensagem.titulo,
            msg: this.mensagem.msg,
            setor_de_recebimento: this.mensagem.setor,
            servidor_id: login_id,
            servidor_id_resposta: id_serv_resp,
          };


          const data = await Comunicacao.enviarMensagem(nv,1);
        }

        this.mensagem.titulo = "";
        this.mensagem.msg = "";
        this.mensagem.setor = "";
        this.pesquisaservidor = "";


        this.mensagemRecebidasMensagensAbertas(1);
        this.mensagemRecebidas(1);
        this.mensagemEnviadas();
        this.popmsg2 = false;
        this.popmsg = false;
        this.responder_msg =0;
        this.resposta_servidor_id =0;
      } catch (e) {
      }
    },

    async listarTudo() {
        try {
            this.escolaALL = [];
            const data = await Escola.obtemTodos();
            for (const el of data.data) {
              const novo = {
                id_: el.id,
                id: el.id,
                logradouro:el.logradouro,
                bairro: el.bairro,
                cep: el.cep,
                cidade: el.cidade,
                cnpj: el.cnpj,
                email: el.email,
                estado: el.estado,
                nome: el.nome,
                numero: el.numero,
              };
              this.escolaALL.push(novo);
            };
            this.escolaALL.reverse();
        } catch (e) {
        }
    },
    async buscarDadosRede(){
      const data = await axios.get('info/qdt/redeensino');
      this.rede = data.data;

      this.gerarVerticalGrafico();
    },
    async buscarDadosRedeEventos(){
        const data = await axios.get('info/qdt/redeensino/eventos');
        this.rede2 = data.data;
        this.gerarDonutGraficoAlunosEspecificos();
    },
    async buscarDadosEscola(){
      if(this.escola == 0){
        this.escola = this.escolaALL[0]["id"]
      }

      const data = await axios.get('info/qdt/escola/'+this.escola+'/'+this.ano);
      this.dados = data.data;
      this.gerarDonutGrafico(this.themes);
    },
    async buscarDadosEscolaAlunosEventos(){
        if(this.escola == 0){
            this.escola = this.escolaALL[0]["id"]
        }
        const data = await axios.get('inf/qdt/escola/eventos/'+this.escola);
        this.dados2 = data.data;
        this.gerarDonutGraficoAlunosEventos();
    },

    async buscarDadosEscolaNotas(){
        if(this.escola == 0){
            this.escola = this.escolaALL[0]["id"]
        }
        const data = await axios.get('inf/qdt/escola/notas/'+this.escola);
        this.dados3 = data.data;
    },

    async pesquisarServidor(){
      try{
        //this.filterservidor = [];
        if(this.pesquisaservidor != null && this.pesquisaservidor != ""){
          const data = await Servidores.pesqServidorCompleto(this.pesquisaservidor);
          this.filterservidor = data.data;
        }
      }catch(e){
        // console.log(e)
      }
    },

    gerarVerticalGrafico(){
        this.redeChartData = null;
        if (this.redeChartData) {
           this.redeChartData.datasets[0].backgroundColor = ['#9727cf','#e42222', '#2c82e0', '#ffd43a', '#154ec1', '#18ad46']
        } else {
            if(this.rede){
                const fund1 = this.rede[0].qdt;
                const fund2 = this.rede[1].qdt;
                const ern = this.rede[2].qdt;
                const eja = this.rede[3].qdt;
                const edi = this.rede[4].qdt;
                const total = this.rede[5].qdt;
                this.redeChartData = {
                    labels: ['Educação Infantil', 'Fundamental I', 'Fundamental II', 'ERN' , 'EJA', 'Total'],
                    datasets: [{
                        label: 'Quantidade de Alunos',
                        backgroundColor: ['#9727cf','#e42222', '#2c82e0', '#ffd43a', '#154ec1','#18ad46'],
                        data: [edi,fund1, fund2, ern, eja, total],
                    }],
                }
            }
        }

    },
    gerarDonutGraficoAlunosEspecificos(){
        this.rede2ChartData = null;
        if (this.rede2ChartData) {
           this.rede2ChartData.datasets[0].backgroundColor = ['#e42222','#2c82e0', '#000000' ]
        } else {
            if(this.rede2){
                const eva = this.rede2.evadidos;
                const tr = this.rede2.transferidos;
                const fa = this.rede2.falecidos;

                this.rede2ChartData = {
                    labels: ['Evadidos','Transferidos', 'Falecidos'],
                    datasets: [{
                        label: 'Alunos Especificos',
                        backgroundColor: ['#e42222','#2c82e0', '#000000'],
                        data: [eva,tr, fa],
                    }],
                }
            }
        }

    },
    gerarDonutGrafico(){
        this.donutChartData = null;
        if (this.donutChartData) {
           this.donutChartData.datasets[0].backgroundColor = ['#9727cf','#e42222', '#2c82e0', '#ffd43a', '#154ec1']
        } else {
            if(this.dados){
                const educInf = this.dados.escola.educacao_infantil;
                const fund1 = this.dados.escola.fundamental_1_qdt;
                const fund2 = this.dados.escola.fundamental_2_qdt;
                const ern = this.dados.escola.ern_qdt;
                const eja = this.dados.escola.eja_qdt;

                this.donutChartData = {
                    labels: ['Educação Infantil','Fundamental I', 'Fundamental II', 'ERN' ,'EJA'],
                    datasets: [{
                        label: 'Quantidade de Alunos',
                        backgroundColor: ['#9727cf','#e42222', '#2c82e0', '#ffd43a', '#154ec1'],
                        data: [educInf,fund1, fund2, ern, eja],
                    }],
                }
            }
        }

    },

    gerarDonutGraficoAlunosEventos(){
        this.donutChartData2 = null;
        if (this.donutChartData2) {
           this.donutChartData2.datasets[0].backgroundColor = ['#e42222','#2c82e0', '#000000' ]
        } else {
            if(this.dados2){
                const eva = this.dados2.evadidos;
                const tr = this.dados2.transferidos;
                const fa = this.dados2.falecidos;

                this.donutChartData2 = {
                    labels: ['Evadidos','Transferidos', 'Falecidos'],
                    datasets: [{
                        label: 'Alunos Especificos',
                        backgroundColor: ['#e42222','#2c82e0', '#000000' ],
                        data: [eva,tr,fa],
                    }],
                }
            }
        }

    }
  },


})


</script>

<style scoped>
  .chart {
    height: 400px;
  }
  .text-right {
    text-align: right;
  }
  .badge-prym{
    color: #fff;
    background-color: #28a0ff;
    font-size: 11px;
    font-weight: 500 !important;
  }
  .badge-seg{
    color: #fff;
    background-color: #d4d4d4;
    font-size: 11px;
    font-weight: 500 !important;
  }

body {
  margin: 0 auto;
  max-width: 800px;
  padding: 0 20px;
}

.container {
  border: 2px solid #dedede;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
}

.darker {
  border-color: #ccc;
  background-color: #ddd;
}

.container::after {
  content: "";
  clear: both;
  display: table;
}

.container img {
  float: left;
  max-width: 60px;
  width: 100%;
  margin-right: 20px;
  border-radius: 50%;
}

.container img.right {
  float: right;
  margin-left: 20px;
  margin-right:0;
}

.time-right {
  float: right;
  color: #aaa;
}

.time-left {
  float: left;
  color: #999;
}
</style>
